<template>


<!-- Modal -->
<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabe" aria-hidden="true">
  <div class="modal-dialog modal-xl ">
    <div class="modal-content">
      <div class="modal-header">
           <div class="container-fluid p-0 m-0">
              <div class="row">
                <div class="col-3 text-start align-self-center mx-0 px-0">
                  <button type="button" class="btn btn-light button-custom" data-bs-dismiss="modal" aria-label="Close"><font-awesome-icon icon="arrow-left" class=" mb-1 ms-1 align-middle"/> Back</button>

                </div>
                <div class="col-6 align-self-center">
                  <span class="ficon"> Hotel Location  </span>
                  </div>
                <div class="col-3 align-self-center text-end mx-0 px-0">
                  <button type="button" class="btn btn-light button-custom" data-bs-dismiss="modal" aria-label="Close"> Close<font-awesome-icon icon="times" class=" mb-1 ms-1 align-middle"/>
                  </button>
                </div>
              </div>
            </div>
      </div>
      <div class="modal-body">
<div class="map-responsive">
  <iframe class=" " :src="`https://maps.google.com/maps?q=${coordinates.lat},${coordinates.long}&hl=en&z=18&amp;output=embed`"   style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
</div>

      </div>

    </div>
  </div>
</div>




</template>

<script>
export default {
  name: "MapModal",
  props: {
    coordinates: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.map-responsive{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
  }
.map-responsive iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}

.ficon {
  font-size: 14px;
  margin-bottom: 0px;
  cursor: pointer;
  color:rgb(80, 76, 76);
  font-weight:550
}

.button-custom:hover {
  color: #0057b7;
}
.button-custom {
  font-size: 13px;
}
  @media (min-width: 576px){
.modal-dialog {

  margin: .5rem auto;
}}
</style>