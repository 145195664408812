<template>
  <div class="m-0 p-0 bg-primary bg-opacity-25 rounded-2 shadow-sm sticky-top-custom  "  >
    <div class="text-start p-3">
      <h5 class="h5b mt-3 text-dark  ">Book this Hotel</h5>
      <hr />
      <div>
        <label for="datepicker-placeholder" class="labelfont  ">Check-in date</label>
        <b-form-datepicker id="datepicker-placeholder" placeholder="Check-in date" locale="en"></b-form-datepicker></div>
      <div class="mt-2">
        <label for="datepicker-placeholder1" class="labelfont  ">Check-out date</label>
        <b-form-datepicker id="datepicker-placeholder1" placeholder="Check-out date" locale="en"></b-form-datepicker>
      </div>
      <div>
        <RoomWidget class="mt-3" />
      </div>
    </div>
    <div class="d-grid gap-2 px-3">
      <div class="form-check text-start mb-1">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
        <label class="form-check-label labelfont" for="flexCheckDefault">I'm traveling for work</label>
      </div>
      <button class="btn btn-primary mb-5" type="button">See Rates</button>
    </div>
  </div>
</template>

<script>
import RoomWidget from "./RoomWidget";
export default {
  components: {
    RoomWidget,
  },
};
</script>
<style lang="scss" scoped>
.labelfont {
  font-size: 13px;

}
.h5b {
  font-weight: 550;
}
.b-form-btn-label-control.form-control::v-deep > .form-control {
  height: auto;
  min-height: calc(calc(1.5em + 0.75rem + 2px) - 2px);
  padding-left: 0.25rem;
  margin: 0;
  border: 0;
  outline: 0;
  background: transparent;
  word-break: break-word;
  font-size: inherit;
  white-space: normal;
  cursor: pointer;
  font-size: 14px !important;
}
.sticky-top-custom {
  position: sticky;
  top: 160px !important;
  z-index: 1020;
}

@media (max-width: 991px) {
  .sticky-top-custom {
    position: relative;
    top: 0px !important;
    z-index: 1020;
  }
}

// .form-control {

//   width: 100%;


//   font-weight: 400;
//   line-height: 1.5;
//   color: #212529;
//   background-color: #fff;
//   background-clip: padding-box;
//   border: 2px solid #FEBB02;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   border-radius: .25rem;
//   transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
// }
// .formborder{
//    border: 2px solid #FEBB02;
// }
</style>