<template>


      <div class="bg-white custom-padding-top   " id="mudhahotel"  >
        <div class="container  ">
            <Breadcrumbs class="bcumb   bg-white mt-0 pt-0 "/>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
                <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                </symbol>
              </svg>
            <div class="alert alert-warning d-flex align-items-center text-start py-2" role="alert">
                <svg class="bi flex-shrink-0 me-2" width="24" height="20" role="img" aria-label="Warning:"><use xlink:href="#info-fill" />
                </svg>
                <div class="alertfont text-dark"> Welcoming, safeguarding and taking care of others is at the very heart
                  of what we do and who we are. New and improved processes have been
                  implemented to ensure the safety and wellbeing of our guests and team
                  members.
                </div>
              </div>
              </div>
           <div class="text-start  d-flex align-items-center   mt-5  ">
                <span class="badge  loc_font1   ">Hotel</span>
             <h3 class="mb-0 me-2 card-title ms-1">{{ hotel.name }} {{ hotel.location.city }} </h3>
                  <HotelCardRating :id="hotel.id" :rating="hotel.rating" class="flex-1 text-end"/>
          </div>
        <div class="text-start  d-flex align-items-center   mb-3 ">
            <i class="fas fa-map-marker-alt text-primary shadow"></i>
              <span class="  loc_font ms-1"> {{ hotel.location.city }} </span>
            <button type="button" class="btn btn-link  btn-custom-link loc_font" data-bs-toggle="modal" data-bs-target="#exampleModal2">show map</button>
        </div>
         <div class="container mx-0 px-0">
              <div class="row">
                <div class="col-sm-12 col-lg-9 pb-4">
                  <div class="position-relative" href=" ">
                        <img :src="require(`@/assets/img/hotels/${hotel.img[0]}.jpg`)" class="w-100 rounded-3 shadow-sm"    />
                            <a href="" class="btn button_class btn-light border-5 rounded-pill btn-sm shadow ficon" data-bs-toggle="modal" data-bs-target="#exampleModal1">
                              <font-awesome-icon icon="arrows-alt" class=" border rounded-pill"/>22 photos/video </a>
                                  <a href="#mapcontent" class="btn button_class1 btn-light border-5 rounded-pill btn-sm shadow ficon"  > <i class="fas fa-map-marker-alt  "></i> See on a map</a>
                                        <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                        <div class="modal-dialog modal-xl">
                                          <div class="modal-content">
                                            <div class="modal-header">
                                                <div class="container-fluid px-0 mx-0">
              <div class="row">
                <div class="col-3 text-start align-self-center mx-0 px-0">
                                 <button type="button" class="btn btn-light button-custom" data-bs-dismiss="modal" aria-label="Close"><font-awesome-icon icon="arrow-left" class=" mb-1 ms-1 align-middle"/> Back</button>

                </div>
                <div class="col-6 align-self-center">
                  <span class=""> {{ hotel.name }} {{ hotel.location.city }}&nbsp;&nbsp;&nbsp; </span>
                  <span class="">
                    <i class="fa fa-star text-warning fa-star-custom"></i>
                    <i class="fa fa-star text-warning fa-star-custom"></i>
                    <i class="fa fa-star text-warning fa-star-custom"></i>
                    <i class="fa fa-star text-warning fa-star-custom"></i>
                    <i class="fa fa-star text-warning fa-star-custom"></i>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                  <button type="button" class="btn btn-primary btn-sm">
                    Reserve Now
                  </button>
                </div>
                <div class="col-3 align-self-center text-end mx-0 px-0">
                  <button
                    type="button"
                    class="btn btn-light button-custom"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Close<font-awesome-icon
                      icon="times"
                      class="ficon ms-1 align-middle"
                    />
                  </button>
                </div>
              </div>
            </div>
                                            </div>
                                            <div class="modal-body">
                                         <HotelCarousel :showcase-img="hotel.img" />
                                            </div>

                                          </div>
                                        </div>
                                      </div>

                    </div>
                       <div class="d-flex align-items-center my-3 bg-white">
                            <div  role="cell" class="svgclass"><div class="svgclass"><span aria-hidden="true" class="svgclass">  <svg viewBox="0 0 128 128" width="1em" height="1em"><path d="M24 88h8v16h-8zm0-16h8V56h-8zm32 32h8V88h-8zm0-32h8V56h-8zm0-32h8V24h-8zm64 16v60a4 4 0 0 1-4 4H12a4 4 0 0 1-4-4V44a4 4 0 0 1 4-4h28V12a4 4 0 0 1 4-4h32a4 4 0 0 1 4 4v58.3l5.2-5.1a4 4 0 0 1 5.6 0l5.2 5.1V56a4 4 0 0 1 .3-1.5l8-20a4 4 0 0 1 7.4 0l8 20a4 4 0 0 1 .3 1.5zM16 112h24V48H16zm32 0h24V16H48v96zm32 0h16V81.7l-8-8-8 8zm32-55.2l-4-10-4 10V112h8z"></path></svg></span></div><div class="svgclass1">City Access</div></div>
                            <div  role="cell" class="svgclass"><div class="svgclass"><span aria-hidden="true" class="svgclass"> <svg viewBox="0 0 128 128" width="1em" height="1em"><circle cx="64" cy="100" r="12"></circle><path d="M118.3 32.7A94.9 94.9 0 0 0 64 16 94.9 94.9 0 0 0 9.7 32.7a4 4 0 1 0 4.6 6.6A87 87 0 0 1 64 24a87 87 0 0 1 49.7 15.3 4 4 0 1 0 4.6-6.6zM87.7 68.4a54.9 54.9 0 0 0-47.4 0 4 4 0 0 0 3.4 7.2 47 47 0 0 1 40.6 0 4 4 0 0 0 3.4-7.2z"></path><path d="M104 50.5a81.2 81.2 0 0 0-80 0 4 4 0 0 0 4 7 73.2 73.2 0 0 1 72 0 4 4 0 0 0 4-7z"></path></svg></span></div><div class="svgclass1">Free Wifi</div></div>
                            <div  role="cell" class="svgclass"><div class="svgclass"><span aria-hidden="true" class="svgclass">  <svg class="bk-icon -iconset-bar hp__important_facility_icon" height="20" width="20" viewBox="0 0 128 128" role="presentation" aria-hidden="true" focusable="false"><rect x="60" y="74" width="8" height="44"></rect><rect x="60" y="94" width="8" height="40" rx="4" ry="4" transform="rotate(-90 64 114)"></rect><path d="M100 34H28a4 4 0 0 0-3.2 6.4l36 48a4 4 0 0 0 6.4 0l36-48A4 4 0 0 0 100 34zM78.7 61.7H49.3l-16-21.2h61.4z"></path><rect x="75.5" y="-1.4" width="6" height="81.9" rx="3" ry="3" transform="rotate(36.31 78.487 39.584)"></rect><circle cx="90.6" cy="21.9" r="8"></circle></svg></span></div><div class="svgclass1">Bar/Restaurant</div></div>
                            <div  role="cell" class="svgclass"><div class="svgclass"><span aria-hidden="true" class="svgclass"> <svg class="bk-icon -iconset-tea_maker hp__important_facility_icon" height="20" width="20" viewBox="0 0 128 128" role="presentation" aria-hidden="true" focusable="false"><path d="M102 40H58a2 2 0 0 0-2 2v46a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-.4a20 20 0 0 0 0-39.2V42a2 2 0 0 0-2-2zM73 80a4 4 0 0 1-8 0V56a4 4 0 0 1 8 0zm39-12a12 12 0 0 1-8 11.3V56.7a12 12 0 0 1 8 11.3zm0-60H16a8 8 0 0 0-8 8v96a8 8 0 0 0 8 8h96a8 8 0 0 0 8-8v-6a2 2 0 0 0-2-2H50a2 2 0 0 1-2-2V26a2 2 0 0 1 2-2h14v8h32v-8h22a2 2 0 0 0 2-2v-6a8 8 0 0 0-8-8zM28 64a8 8 0 1 1 8-8 8 8 0 0 1-8 8zm0-24a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"></path></svg></span></div><div class="svgclass1">Coffee maker</div></div>
                            <div  role="cell" class="svgclass"><div class="svgclass"><span aria-hidden="true" class="svgclass"> <svg class="bk-icon -iconset-parking_sign hp__important_facility_icon" height="20" width="20" viewBox="0 0 128 128" role="presentation" aria-hidden="true" focusable="false"><path d="M70.8 44H58v16h12.8a8 8 0 0 0 0-16z"></path><path d="M108 8H20A12 12 0 0 0 8 20v88a12 12 0 0 0 12 12h88a12 12 0 0 0 12-12V20a12 12 0 0 0-12-12zM70 76H58v24H42V28h28a24 24 0 0 1 0 48z"></path></svg></span></div><div class="svgclass1">Free Parking</div></div>
                        </div>
                           <NavBarItem  />
                           <div>    <div class="row mt-5">
                        <div class="col-sm-12 col-lg-8 ">
                       <section id="hoteldesc"   class=" text-start  ">
                         <h3 class="section-title">{{ hotel.name }} {{ hotel.location.city }} </h3>
                         <h5 class="section-title mb-4 ">Our world-class service will make you feel like a celebrity</h5>
                         <div class="    ">
                          <p  class="card-text-custom   ">{{ hotel.desc }}</p></div>

                         <div class="accordion accordion-flush border-0" id="accordionFlushExample">
                           <div class="accordion-item border-0">
                                <div id="flush-collapseOne" class="accordion-collapse collapse " aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body text-start m-0 p-0 ">  <p  class="card-text-custom">{{ hotel.desccollapse }}</p> </div>
                              </div>
                            </div>
                              <button class="accordion-button collapsed btn-sm py-2  pe-1 ps-0 mx-0  " type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                See More
                                </button>

                          </div>
                        </section>
                       </div>
                         <div class="col-sm-12 col-lg-4">
                            <PropertyHighlights />
                         </div>
                         <hr class="hr-class-custom">
                        </div>

                           <section id="mapcontent" class="mt-3 pt-2 text-start  ">
                         <h3 class="section-title mb-4   ">Hotel Location </h3>
                            <b-embed class="embed-responsive border shadow-sm" type="iframe" aspect="16by9" :src="`https://maps.google.com/maps?q=${hotel.location.coordinates.lat},${hotel.location.coordinates.long}&hl=en&z=18&amp;output=embed`"></b-embed>


                           <h5 class="section-title   mt-4">{{ hotel.name }} {{ hotel.location.city }} </h5>
                           <span class="card-text-custom mt-4 ">{{ hotel.name }} {{ hotel.location.addr }} </span>
                            <div class="d-flex mt-2">
                         <span class="card-text-custom me-4 text-muted"> <font-awesome-icon icon="phone"/> {{hotel.phone}} </span>
                              <span class="card-text-custom me-4 text-muted"> <font-awesome-icon icon="fax"/> {{hotel.fax}}</span>
                                    <span class="card-text-custom text-muted"> <font-awesome-icon icon="envelope"/> {{hotel.mail}}</span>
                              </div><hr class="hr-class-custom">
                     </section>
                        <section class="mt-5 " id="hotelfacilities">
                         <h3 class="section-title   text-start  mb-0 ">Hotel Services </h3><br>
                         <div class=" text-start mb-2">
                            <i class="fas fa-clock  text-primary amnities"></i>
                            <span class="card-text-custom  "> <b> Check-in from 14h00 - Check out up to 12h00</b></span>
                            </div>
                           <div class="d-flex flex-wrap gap-1 mb-3 flex-1">

                              <div v-for="facility, i in hotel.facilities" :key="i" >

                                <i class=" me-1 text-primary amnities" :class="facilityIcons[facility]" ></i>
                                <span class="text-muted card-text-custom "> {{ facility }} </span>
                              </div>
                                <!-- <img v-for="image, i in hotel.img" :key="i" :src="require(`@/assets/img/hotels/${hotel.img[0]}.jpg`)" > -->
                            <span class="card-text-custom "><b> ATM & Currency Exchange:</b> Need cash? There's an ATM and a currency exchange service at this property. </span>
                            </div><hr class="mt-5 hr-class-custom">
                      </section>
                                    <section class="mt-5 " id="roomdesc">
                                            <h3 class="section-title   text-start  mb-0 ">Rooms and Suites </h3><br>


                                            <p   class="card-text-custom">{{ hotel.descroom }}</p>

                                            <!-- <RoomAndSuitesCarousel /> -->
                                         <hr class="mt-5 hr-class-custom">

                                        </section>
                         </div>


           </div>
            <div class="col-sm-12 col-lg-3">
                        <BookNowSideBar />
                    </div>
         </div>
        </div>



           </div>
             <MapModal :coordinates="hotel.location.coordinates"/>
        </div>




</template>

<script>
import HotelCardRating from "@/components/MudhaHotelsSomalilandPage/HotelCardRating"
// import PhotosModalheader from "@/components/modals/PhotosModalheader";
import MapModal from "@/components/modals/MapModal";
import NavBarItem from "@/components/MudhaHotelsSomalilandPage/NavBarItem.vue";
import PropertyHighlights from "@/components/MudhaHotelsSomalilandPage/PropertyHighlights.vue";
// import RoomAndSuitesCarousel from "@/components/MudhaHotelsSomalilandPage/RoomAndSuitesCarousel.vue";

import facilityIcons from "@/assets/data/maps/facility-icons"
import BookNowSideBar from "@/components/MudhaHotelsSomalilandPage/BookNowSideBar.vue";
import HotelCarousel from "@/components/modals/HotelCarousel.vue"



export default {
  name: 'hotel',
   methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
    components: {

 MapModal,
  HotelCardRating,
   NavBarItem,
   HotelCarousel,
  BookNowSideBar,
//  PhotosModalheader,
 PropertyHighlights,
//  RoomAndSuitesCarousel

},

  data() {
    return {
      hotel: this.$store.getters.hotel(this.$route.params.id),
       facilityIcons,
    }
  },


};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alata&display=swap");
.custom-padding-top{
  padding-top:151.5px
}
.amnities{
  font-size:13px
}
.breadcrumb {
  display: flex;
  flex-wrap: nowrap;
  padding: 7px 0px;
  margin-bottom: 0rem;
  list-style: none;
  text-decoration: none !important;
font-size:13px;
    color: #000   !important;

}
.bcumb::v-deep .breadcrumb-item + .breadcrumb-item::before {

  padding-right: .5rem;

  padding-left: .5rem;

 content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='black'/%3E%3C/svg%3E");
}
.breadcrumb-item::v-deep li a{
  color:red !important
}

.bcumb::v-deep .breadcrumb-item a {
  color: #0057b7 !important;

font-size:13px;
  text-decoration: none;
  font-weight:400;
  flex-wrap:wrap
}
.bcumb::v-deep .breadcrumb-item a:hover {

  text-decoration: underline;
  font-size:13px !important;

}
  // @media (min-width: 768px) {
  //     .container{
  //        width:600px;
  //     }
  // }

  // @media (min-width: 992px) {
  //     .container{
  //           width:1140px;
  //     }
  // }

  // @media (min-width: 1200px) {
  //     .container{
  //          width:1110px;
  //     }
  // }
.alertfont{font-size:14px;
font-weight:600}
.loc_font1{
  font-size:10px;
  background-color: #aeaeae
}
.card-title {
  font-family: "Alata", sans-serif;

  font-weight: 550;
  color: black;
}
.section-title {
  font-family: "Alata", sans-serif;

  font-weight: 550;
  color: black;
}
.loc_font {
  font-size: 13px;
  font-weight: 550;

}
.card-title {
  font-family: "Alata", sans-serif !important;

  font-weight: 550;
  color: black;
}
.button_class {
  position: absolute;
  bottom: 20px;
  right: 15px;
}
.button_class1 {
  position: absolute;
  bottom: 20px;
  left: 15px;

}
.ficon {
  font-size: 14px;
  margin-bottom: 0px;
  cursor: pointer;
  color:rgb(80, 76, 76)
}
.ficon:hover {
   color:rgb(0, 0, 0)
}
.ficon1 {
  font-size: 18px;
  margin-bottom: 0px;
  cursor: pointer;
  color:rgb(63, 61, 61)
}
.ficon1:hover {
 color:rgb(0, 0, 0)
}
.ficon2 {
  font-size: 15px;
  margin-bottom: 2px;
  cursor: pointer;
}
.ficon2:hover {
  font-size: 15px;
  margin-bottom: 2px;
  color: #0057b7;
}
.svgclass {
  -ms-flex-item-align: start;
  align-self: flex-start;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  max-width: 100%;
  padding: var(--bui_unit_medium) var(--bui_unit_small);
  text-align: center;
  color: rgb(95, 93, 93);

}
.svgclass1 {
  -ms-flex-item-align: start;
  align-self: flex-start;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  max-width: 100%;
  padding: var(--bui_unit_medium) var(--bui_unit_small);
  text-align: center;
  color: rgb(95, 93, 93);
  font-size:13px

}
.card-text-custom{
  font-size:14px
}
.btn-custom-link:focus {
  outline: none;
  box-shadow: none;
}

.hr-class-custom {
  margin: 1rem 0;
    margin-top: 1rem;
  color: #a1a4a8 !important;
  background-color: currentColor;
  border: 0;
  opacity: .25;
}
section {
  scroll-margin-top: 13rem;
}

.accordion-button:not(.collapsed) {
  color: #1264a3;
  background-color:white !important;
  box-shadow: none !important;
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 15%;

  font-size: 13px;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
  font-weight: 550;
}
.accordion-button:focus {
  outline: none;
  box-shadow: none;
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100% !important;
  height:550px !important;

  padding: 0;
  overflow: hidden;
}
  @media (min-width: 576px){
.modal-dialog {

  margin: .5rem auto;
}}
.ficon {
  font-size: 14px;
  margin-bottom: 0px;
  cursor: pointer;
  color:rgb(80, 76, 76);
  font-weight:550
}

.button-custom:hover {
  color: #0057b7;
}
.button-custom {
  font-size: 13px;
}
</style>