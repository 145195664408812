<template>
  <section>
    <b-carousel
      id="carousel-no-animation"
      v-model="slide"
      controls
      indicators
      no-animation
      :interval="10000"
    >
      <b-carousel-slide v-for="(img, imageIndex) in hotel.img" :key="img">
        <template #img>
          <div>
            <img
              :src="require(`@/assets/img/hotels/${hotel.img[imageIndex]}.jpg`)"
              class="img-fluid rounded-2 p-0 m-0 shadow"
              :key="imageIndex"
            />
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <div
      class="d-flex bg-whitemt-1"
      data-bs-spy="scroll"
      style="width: 1106px; overflow-x: scroll"
    >
      <div
        v-for="(img, imageIndex) in hotel.img"
        :key="img"
        @click="slide = imageIndex"
        class="pt-2 pb-2 div-custon"
      >
        <img
          :src="require(`@/assets/img/hotels/${hotel.img[imageIndex]}.jpg`)"
          class="thumbnails rounded-3 custom-padding focus"
        />
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: "HotelCarousel",

  data() {
    return {
      hotel: this.$store.getters.hotel(this.$route.params.id),
      slide: 0, // active slide index
    };
  },
  methods: {
    activateImage(imageIndex) {
      this.activeImage = imageIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-padding {
  padding: 0 2px;
  cursor: pointer;
}

.custom-padding:hover {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
  filter: rgb(90, 89, 89); /* IE6-9 */
  -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
}

.focus:focus {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
  filter: rgb(90, 89, 89); /* IE6-9 */
  -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
}
.thumbnails {
  width: 110.6px;
  height: 59.96px;
}
</style>

